@import '../../style/variables.scss';

.char {
    &__info {
        padding: 25px;
        color: $white;
        border-radius: $border-radius;
        background-color: $grey-500;
        &-name {
            font-weight: bold;
            font-size: 22px;
            line-height: 29px;
            text-transform: uppercase;
        }
        .skeleton {
            margin-top: 30px;
        }
    }
    &__basics {
        display: grid;
        grid-template-columns: 150px auto;
        column-gap: 25px;
        img {
            border-radius: 50%;
            padding: 10px;  
            border: 2px solid $main-color;
            width: 150px;
            height: 150px;
            object-fit: cover;
        }
    }
    &__btns {
        display: flex;
        gap: 10px;
        margin-top: 35px;
    }
    &__descr {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
    }
    &__comics {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin-top: 20px;
        &-list {
            position: relative;
            margin-top: 10px;
        }
        &-item {
            color: $grey;
            width: 100%;
            padding: 7px 0;
            margin-top: 10px;
        }
    }
    &__select {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }

}