@import '../../style/variables.scss';

.char {
    &__content {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 650px 425px;
        column-gap: 25px;
        align-items: start;
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 200px);
        column-gap: 25px;
        row-gap: 30px;
        margin-bottom: 25px;
    }
    &__item {
        width: 200px;
        height: 318px;
        background-color: $grey-500;
        padding: 15px;
        border-radius: $border-radius;
        cursor: pointer;
        transition: 0.3s transform ease;
        img {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            width: 200px;
            height: 200px;
            object-fit: cover;
            transform: translate(-15px, -15px);
        }
        &:hover {
            background-color: $main-color;
            transform: translateY(-8px);
        }
        &_selected {
            background-color: $main-color;
            transform: translateY(-8px);
        }
    }
    &__name {
        font-weight: bold;
        font-size: 22px;
        line-height: 29px;
        text-transform: uppercase;
        color: #fff;
    }
    
}